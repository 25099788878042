
body {
  background-color: #ded2c5;
  margin: 0;
}

#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
  color: #fff;
}

#app > div {
  flex: 1;
}

.logo {
  display: flex;
  justify-content: center;
  flex: 0.6 !important;
  img {
    max-width: 60%;
  }
}

.tg {
  display: flex;
  align-items: center;
  text-decoration: unset;
  flex: 0.7 !important;
}

.tg img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.tg button {
  display: flex;
  align-items: center;
  border-radius: 39px;
  background-color: #2299D6;
  border: 0;
  color: #FFF;
  height: 43px;
  font-size: 20px;
  padding: 0 30px;
}

.links {
  width: 100%;
  display: flex;
  padding-bottom: 30px;
}

.links .iron {
  background-image: url('../img/iron.png')
}

.links .marble {
  background-image: url('../img/marble.png')
}

.links .mission {
  background-image: url('../img/mission.png')
}

.link-item {
  cursor: pointer;
  background-size: cover;
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}

.link-item .icon {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.link-item .icon img {
  width: 30px;
}
.link-item .title {
  position: relative;
  z-index: 2;
  font-size: 13px;
}

.link-item:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: black;
  filter: opacity(0.3);
  z-index: 0;
}

@media (max-width:360px) {
  .link-item .title {
    font-size: 11px;
  }
}
